@import "./assets/css/standed_variables.scss";
@import 'material-icons/iconfont/material-icons.scss';

@font-face {
    font-family: "Lato";
    src: url("./assets/fonts/Lato-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

.custme-p {
    position: relative;
    top: 2px;
    padding: 0.15rem 2.25rem 0.15rem 0.5rem;
}

.cricle {
    height: 10px;
    width: 10px;
    border-radius: 50%;
}

.planmenu-list {
    width: 337px !important;
    border-radius: 2px;
    box-shadow: #2f2f2f0a 0 2px 4px, #2f2f2f1f 0 8px 16px;
}

.menu-list {
    width: 250px !important;
    border-radius: 2px;
    box-shadow: #2f2f2f0a 0 2px 4px, #2f2f2f1f 0 8px 16px;
}

.droparrow::after {
    border: 2px solid #272f3b29;
    border-bottom: none;
    border-right: none;
    width: 16px;
    height: 16px;
    background: $white;
    z-index: 1;
    left: 13px;
    top: 1%;
    margin: -10px 0 0 -10px;
    transform: rotate(45deg);
}

.droparrow::after {
    content: '';
    position: absolute;
    z-index: -1;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}

.scrollarea {
    overflow-y: auto;
}

.border-bottom {
    border-bottom: 1px solid #dee2e6 !important;
}

// sidebar css
.side-navbar {
    width: $width;
    height: $height;
    position: fixed;
    background-color: $sidebarbg;
    transition: 0.5s;
}

.nav-link:active,
.nav-link:focus,
.nav-link:hover {
    background-color: #ffffff26;
}

.my-container {
    transition: 0.4s;
}

.active-nav {
    margin-left: 0;
}

#menu-btn {
    background-color: #100901;
    color: $white;
}

.list-group-item {
    position: relative;
    display: block;
    display: flex;
    align-items: center;
    padding: 0.6rem 1rem;
    color: $textcolor-control;
    text-decoration: none;
    background-color: $white;
    border-bottom: 1px solid rgb(0 0 0 / 6%);
}

.list-group-item label {
    margin-left: 10px;
}

// ----------- Header background image--------------------------------
header .logoutbtn {
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    border-radius: 16px;
    // background: #eff2f9 url(../../../assets/images/icon-logout.png) no-repeat 9px 8px;
    background: #eff2f9 url(./assets/images/icon-logout.png) no-repeat 9px 8px;
    ;
    background-size: 15px auto;
    padding: 9px 2px 2px 31px;
    margin-left: 15px;
    display: inline-block;
    width: 32px;
    height: 32px;
    font-size: 12px;
    line-height: 14px;
    font-weight: 700;
    text-indent: -10000em;
    text-align: left;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    vertical-align: middle;
}

.englishIcon,
.spanishIcon,
.frenchIcon,
.germanIcon,
.portugueseIcon,
.dutchIcon,
.latvianIcon,
.lithuaniaIcon,
.polishIcon,
.danishIcon,
.italianIcon,
.hungarianIcon,
.colombiaIcon,
.norwegianIcon,
.romanianIcon {
    background: url(./assets/images/flag-sprite.png);
}

header .navbar .nav li a span {
    width: 35px;
    height: 35px;
    background-size: 334px auto;
    // background-image:url(../../../assets/images/sprite-nav.png) ;
    background-image: url(./assets/images/sprite-nav.png);
    background-repeat: no-repeat;
    display: block;
    margin: 0 auto;
}

//   ----- Preview section css---------------
.thumbnail {
    height: 175px;
    // width: 150px;
    // width: 134px;
}

.preview-img {
    // width: 150px;
    // height: 175px;
    width: 100%;
    height: auto;
    padding-bottom: 10px;
    // border: 1px solid #d2d2d5;
    // box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
}

.icon-res {
    // width:100%;
    // height: auto;
    // display: block;
    cursor: pointer;
}

.icon-plan {
    width: 22px;
    height: auto;
    position: relative;
    top: 0px;
    margin-right: 3px;
}

.icon-undoredo {
    width: 20px;
    height: auto;
    position: relative;
    top: 0px;
}

.icon-dependecie {
    width: 24px;
    position: relative;
    top: 0px;
}

.page-box {
    width: 150px;
    height: 175px;
    padding-bottom: 3px;
    overflow: hidden;
    border: 1px solid #d2d2d5;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
}

.thumbnails-page-btn:before {
    // border-color: transparent transparent transparent #b8b6b6f7;
    border-color: transparent transparent transparent #a49f9ff7;
    position: absolute;
    bottom: -1px;
    left: -1px;
    border-width: 30px 0 0 30px;
    border-width: 36px 0 0 53px;
    border-style: solid;
    content: "";
    transform: rotate(360deg);
}

.pagenum {
    position: relative;
    left: 3px;
    color: $white;
    font-weight: 600;
}

.pre-icons {
    position: absolute;
    right: 10px;
    top: -5px;
    opacity: 0.6;
    cursor: no-drop;
}

.pre-icons .font20 {
    color: #100901;
    opacity: 0.5;
    cursor: no-drop;
}

.badge-icon {
    transform: rotate(90deg);
    color: #a7a2a2;
    position: relative;
    left: -18px;
    bottom: 178px;
}

// .active .preview-img {
//     border: 2px solid rgb(47, 45, 46);
// }
.active .page-box {
    border: 2px solid rgb(47, 45, 46);
}

.active .thumbnails-page-btn:before {
    // border-color: transparent transparent transparent rgb(36 134 252);
    border-color: transparent transparent transparent rgb(47, 45, 46);
}

.active .pre-icons .material-icons {
    color: $primary;
}

.active .badge-icon {
    color: #2f2d2e;
    position: relative;
    left: -18px;
    margin-top: 0px;
}

.document-name {
    // background-color: #f8f9fc;
    border-bottom: 1px solid #cbcdd2f7;
}

// leftside panel colors
.recipent-bg {
    width: 100%;
    background: $primary;
    padding: 7px 6px;
}

.recipent-bg svg,
.recipent-bg label {
    color: $white;
}

.list-group-item {
    padding: 0.45rem 1rem;
}

.rs-storke {
    stroke-width: 3px;
    stroke: $white;
    height: 22px;
    margin-left: 0px;
}

.in-storke {
    stroke-width: 1px;
    stroke: #172b4d;
    color: $black;
}

// ---second one in leftside panel design
.rect-box {
    background: $primary;
    background-color: $success;
    color: $white;
    padding: 4px 5px;
    border-radius: 3px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}

.rs-padding {
    padding: 4px 3px;
}

.list-group-item label {
    margin-left: 10px;
    width: inherit;
    font-weight: 500;
}

// li.list-group-item label:hover {
//     background: #ebebeb;
//     font-weight: 600;
// }
.control-box:hover {
    background: $controlhover-bg;
    font-weight: 600;
    // box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
    // border: 1px solid #c9c9cb;
}

.control-box .list-group-item label:hover {
    padding: 5px 0px;
}

.smalltext {
    font-size: 10px;
    color: $warning;
    font-weight: 600;
    margin-bottom: 10px;
    display: flex;
}

.toast-close-button {
    position: relative;
    right: -0.13em;
    top: -0.53em;
    float: right;
    font-size: 20px;
    font-weight: bold;
    color: #FFFFFF !important;
    text-shadow: 0 1px 0 #ffffff;
    /* opacity: 0.8; */
}

.toast-close-button:hover,
.toast-close-button:focus {
    color: #FFFFFF !important;
    opacity: 1 !important;
}

.toast-top-right {
    top: 45px !important;
    // right: 12px;
    right: 8px !important;
}

.toast-message {
    // word-wrap: initial;
    width: 100%;
    //text-wrap: nowrap;
}

.toast-container .ngx-toastr {
    width: 325px;
}

.box25 {
    display: flex;
    border-radius: 3px;
    background-color: $white;
    margin-right: 12px;
    margin-right: 6px;
    vertical-align: middle;
    border: 1px solid #d1d1d1;
    padding: 4px;
    padding-bottom: 0px;
    min-width: 2rem;
    align-items: center;
    width: 30px;
    height: 30px;
    justify-content: center;
    cursor: pointer;
}

.box25:hover {
    background-color: #f0f8ff;
    cursor: pointer;
}

.dropdown-menu.show {
    display: block;
    border-radius: 0px 0px 0px 0px;
}

// ----------- Search styles ------------------------
.search {
    padding: 0.5rem 0.75rem;
    padding-left: 1.9rem;
    border: 1px solid rgb(171 171 171 / 72%) !important;
    // border: 1px solid #b7b4b4;
    font-size: 14px;
    height: 36px;
}

.search-icon {
    position: absolute;
    z-index: 11;
    top: 19px;
    left: 16px;
    color: #272f3bbf;
}

.listborder {
    border-bottom: 1px solid rgba(0, 0, 0, .06) !important;
}

.pdfcard {
    background: $white;
    border-radius: 4px;
    margin-bottom: 1rem;
    -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    // box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
    // box-shadow: 0 1px 2px 0 rgba(0,0,0,0.05);
    display: flex;
    justify-content: center;
    position: relative;
}

.img-div {
    position: relative;
    width: auto;
    max-width: 100%;
    border-bottom: 2px solid #b8b8b8bf;
    margin: 0 auto;
}

.form-control {
    border: 1px solid #abababeb;
    border-radius: 4px;
}

.img-control {
    position: relative;
    border: 1px solid #dbdbdb;
    // width: 915px;
    margin: 0px auto;
    zoom: 1;
    border-bottom: 1px solid #b8b8b8bf;
    margin-bottom: 0.5rem;
}

.document-img {
    height: 100%;
    // width: 915px;
    display: block;
}

.docImages {
    position: absolute;
    height: calc(100vh - 9.25rem);
    overflow: scroll;
    overflow-y: auto;
    padding-right: 5px;
    padding-right: 1px;
    // display: flex;
}

.env-footer {
    margin-top: 0.75rem;
    // position: absolute;
    width: 100%;
}

// ------Control active-------------
.rotate {
    background-color: #c7cfd6;
}

.visited,
.visiteditalic {
    background-color: $controlhover-bg;
    cursor: pointer;
}

.controlactive .control-box {
    background: #f8f8ff;
    border: 1px solid #c9c9cb;
}

.controlactive .control-box label {
    color: $textcolor-control;
    font-weight: 600;
}

.controlactive:before {
    content: "";
    display: block;
    width: 32px;
    height: 3px;
    background: $bg-secondary;
    left: -14px;
    top: 47%;
    border: 1px solid #212529;
    position: absolute;
    transform: rotate(90deg);
}

.controlactive .control-box::before {
    border-top: 8px solid transparent;
    border-left: 10px solid #fff;
    content: "";
    display: block;
    opacity: 1;
    border-left: 6px solid #212529;
    border-bottom: 6px solid transparent;
    position: absolute;
    top: 48%;
    left: 2px;
    transform: translateY(-50%);
}

.parentul .controlactive .control-box::before,
.parentul .controlactive:before {
    visibility: hidden;
}

.parentul .controlactive .control-box label {
    color: $textcolor-control;
    font-weight: 500;
}

.parentul .controlactive .control-box {
    background: #ffffff;
    border: none;
}

@media (min-width: 1420px) and (max-width: 1499px) {
    .img-control {
        width: 915px;
    }

    .document-img {
        height: 100%;
        // width: 825px;
        display: block;
    }
}

.no-fields img {
    width: 88px;
}

// ----modals css-----------
.add-option1 {
    color: #3ab16d;
    font-size: 2rem;
    cursor: pointer;
}

.controlshadow {
    background-color: #fff;
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.controlshadow td {
    vertical-align: middle;
    padding: 5px;
}

table {
    border-collapse: separate;
    border-spacing: 0 6px;
}

.icon-font {
    font-size: 1.35rem;
}

//   ------------- view settings---------------
.setting-table {
    .left-space {
        // border-left: 0.25em solid #f2f5f7;
        border-left: 0.25em solid #ffffff;
        text-align: center;
    }
}

.tablehead {
    background-color: #e5e5e5;
    background-color: #ebebeb;
    background: #e5edff;
    background-color: #272f3b;
    background: rgb(224, 228, 238);

    th {
        padding: 8px;
        padding-left: 16px;
        color: #5a6270;
    }
}

.table-space {
    border-spacing: 0 2px;
}

.tablehead1 {
    background: rgb(224, 228, 238);

    th {
        padding: 8px;
        padding-left: 8px;
        color: #5a6270;
    }
}

.table-scroll {
    overflow-y: auto;
    height: auto;
    max-height: 225px;
}

.option-scroll {
    overflow-y: auto;
    width: 100%;
    height: auto;
    max-height: 200px;
}

.text-danger {
    color: #d21513 !important;
}

.top10 {
    position: relative;
    top: 9px;
}

.add-btn,
.view-btn {
    position: relative;
    height: 32px;
    width: 110px;
    background: #3ab16d;
    border: 1px solid #3ab16d;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 40px;
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    color: #fff;
    text-align: center;
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
    transition: all 0.2s;
    -webkit-user-select: none;
    user-select: none;
    letter-spacing: 1px;
    border-radius: 0.15rem;
}

.view-btn {
    width: 120px;
    // background-color: #1d4ed8;
    // border: 1px solid #1d4ed8;
    background: #fff;
    border: 1px solid #f1f1f1;
    color: #172b98e8;
    text-wrap: nowrap;
    // color: #2486fc;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 12px;
    background: #fefefe;
    border: 1px solid #172b98e8;
}

.w-30 {
    width: 31%;
}

.w-40 {
    width: 40%;
}

.w-5 {
    width: 5%;
}

.w-20 {
    width: 20%;
}

.pl-18 {
    padding-left: 19px !important;
}

.left-n10 {
    position: relative;
    left: -10px;
}

.setting-header {
    background-color: #f3f4f6;
    background-color: #ebebeb;
    background: #efefef;
    font-weight: 600;
    width: 100%;
    padding: 0.55rem 0.9rem;
    font-size: 14px;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
}

.text-green {
    color: #3ab16d;
}

.menu-list1 {
    width: 50px !important;
    border-radius: 2px;
    box-shadow: #2f2f2f0a 0 2px 4px, #2f2f2f1f 0 8px 16px;
}

.tableViewAllBody td p {
    // margin-left: 20px;
    // margin-bottom: 5px;
    margin: 0;
    background: #fff;
    padding: 0px 0px;
    color: #000;
    cursor: no-drop;
    opacity: 0.85;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    // height: 33px;
}

.tableViewAllBody tr:last-child>td {
    border-bottom: 0 !important;
    border-bottom: none;
}

.recpientlist {
    // min-width: 15.45rem;
    width: inherit;
    width: -webkit-fill-available;
    border-radius: 0px 0px 8px 8px;
    max-height: 190px;
    overflow: auto;
}

.recipientDeatilsItem {
    display: flex;
    align-items: center;
    padding-left: 10px;
}

.square-font {
    font-size: 20px;
    border-radius: 4px;
    margin-right: 8px;
}

.square-select {
    font-size: 18px;
    border-radius: 4px;
}

.docImages {
    // background-color: #eee;
    background-color: #c3c3c3;
    position: relative;
    width: 100%;
    overflow: auto;
    height: calc(100vh - 12.75rem);
    margin-bottom: 6px;
}

.loader {
    position: fixed;
    opacity: 0.8;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 1000001;
    color: #fff;
    background-image: url(./assets/images/rsign-loader1.gif);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-color: rgba(255, 255, 255, 0.5);
    background-blend-mode: screen;
    padding: 10px;
}

.global-page {
    background-image: url(./assets/images/bg-new.png);
    // background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: 100% 100%;
}

.paginationLoader {
    opacity: 0.8;
    color: blue !important;
}

.doc-head {
    font-size: 0.9rem;
}

.pl-3p {
    padding-left: 3px;
}

.ml-15 {
    margin-left: 12px;
}

.ml-9 {
    margin-left: 4px;
}

.font-recipent {
    white-space: nowrap;
    width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.w-add95 {
    width: 94%;
    margin-left: 9px;
}

.w-add96 {
    width: 97%;
    margin-left: 8px;
}

.rotate270 {
    transform: rotate(270deg);
}

// ----Zoom styles---------
.zoomflex-50,
.zoomflex-75,
.zoomflex-100,
.col-lg-10>.middle-scrollbar>.zoomflex-100,
.col-lg-10>.middle-scrollbar>.zoomflex-75,
.col-lg-10>.middle-scrollbar>.zoomflex-50 {
    display: flex;
    justify-content: center;
}

// .zoomflex-100{
//     position: absolute;
// }
.zoomflex-125,
.zoomflex-150,
.zoomflex-175,
.zoomflex-200 {
    display: flex;
    justify-content: start;
}

.zoomclass-50,
.zoomclass-75,
.zoomclass-100 {
    transform-origin: center top;
}

.zoomclass-125,
.zoomclass-150,
.zoomclass-175,
.zoomclass-200 {
    transform-origin: left top;
}

// .zoom-50{
//     margin-bottom: calc(100vh - 267.15rem);
// }
// .zoom-75{
//     margin-bottom: calc(100vh - 155.25rem);
// }
.zoom-more {
    padding-bottom: calc(100vh - 43.44rem);
}

.col-lg-10>.middle-scrollbar>.zoomflex-125 {
    display: flex;
    justify-content: center;
}

.col-lg-10>.middle-scrollbar>.zoomflex-125>.zoomclass-125 {
    transform-origin: center top;
}

.cdk-drop-list:focus,
.zoompage:focus,
.cdk-drop-list:focus-visible,
.zoompage:focus-visible {
    outline: none !important;
    // outline: -webkit-focus-ring-color auto 1px;
}

.img-control:focus-visible {
    outline: none !important;
}

.img-control {
    -webkit-perspective: 1000;
    -webkit-backface-visibility: hidden;
}

.usercheck:before {
    visibility: hidden !important;
}

.control-box,
.control-box:hover {
    cursor: pointer;
}

.control-box label {
    cursor: pointer;
}

//-----------------Carousels styles---------------------
.carousel-inner {
    margin: 10px 45px !important;
}

.carousel-control-prev-icon {
    position: absolute;
    left: 15px;
    background: black;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
    background-position: center;
    background-size: 85% 60%;
    fill-opacity: 1;
    background-repeat: no-repeat;
    border-radius: 3px;
    margin-top: 30px;
}

.carousel-control-next-icon {
    position: absolute;
    right: 15px;
    background: black;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-position: center;
    background-size: 85% 60%;
    fill-opacity: 1;
    background-repeat: no-repeat;
    border-radius: 3px;
    margin-top: 30px;
}

.carousel-item {
    width: auto !important;
    perspective: 875px;
}

.carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 6%;
    padding: 0;
    color: #fff;
    text-align: center;
    background: 0 0;
    border: 0;
    opacity: .5;
    transition: opacity .15s ease;
}

.carousel-control-next {
    position: absolute;
    top: 0;
    width: 5%;
}

.carousel {
    position: static;
}

button.accordion1.active1:after {
    content: "\e5ce" !important;
}

button.accordion.active:after {
    content: "\e5ce" !important;
}

.accordion1 {
    // background-color: rgba(235, 236, 240, 0.8588235294);
    background-color: #ffffff;
    // color: #111;
    opacity: 0.8;
    cursor: pointer;
    padding: 8px 10px;
    width: 100%;
    border: none;
    text-align: left;
    height: 35px;
    outline: none;
    border-top: 1px solid rgba(179, 183, 189, 0.3215686275);
    font-size: 14px;
    margin-bottom: 0px;
    transition: 0.4s;
    box-shadow: 0 2px rgb(0 0 0 / 9%);
    font-weight: 500;
}

.active1,
.accordion1:hover {
    color: #111;
    font-weight: 500;
    // background: rgba(209, 227, 246, 1);
    background: #f3f4f6;
    background: #edeff2;
}

button.accordion1.active:after {
    font-size: 18px;
    color: #111;
    font-weight: bold;
    float: right;
    margin-left: 5px;
}

button.accordion1:after {
    font-size: 24px;
    font-weight: 500;
    color: #111;
    top: -5px;
    position: relative;
}

button.accordion1:after {
    font-family: "Material Icons";
    content: "\e5cf";
    color: #777;
    font-weight: bold;
    float: right;
    margin-left: 5px;
}

.add-text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.accordion-item:last-of-type {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}

.accordion-item:first-of-type {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.place-on-initials-btn {
    display: none !important;
}

.ui-resizable-se {
    opacity: 0 !important;
    position: absolute;
    left: 100%;
    top: 100%;
    transform: translate3d(-50%, -50%, 0) rotateZ(45deg);
    border-style: solid;
    border-width: 8px;
    border-color: transparent transparent transparent #eee;
}