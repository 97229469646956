/* You can add global styles to this file, and also import other style files */

@import "./assets/css/standed_variables.scss";

html {
    height: -webkit-fill-available;
  }

body {
    font-family: $fontFamily;
    font-size: 14px;
    line-height: 1.5;
    color: $textcolor-theme;
    // background-color: $body-bg;
    background-color: #ffffff;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    overflow: hidden;
    user-select: none;
}
.body-bg
{
    background: #c3c3c3;
}

p, pre, blockquote, ol, ul, menu, li, dl, dt, dd, a, em, strong, small, s,
cite, q, dfn, abbr, code, sub, sup, i, b, span, ins, del, caption, th, td,
form, label, button, datalist, optgroup, output, progress, meter, fieldset, legend, details, summary, dialog, slot {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: geometricprecision;
    font-family: $fontFamily;
}

//------------------ Font-Sizes-----------------------
.font26{
    font-size: 26px;
}
.font22{
    font-size: 22px;
}
.font21{
    font-size: 21px;
}
.font20{
    font-size: 20px;
}
.font18{
    font-size: 18px;
}
.font17{
    font-size: 17px;
}
.font16{
    font-size: 16px;
}
.font15{
    font-size: 15px;
}
.font14, .form-select, .form-control, input, select, textarea{
    font-size: 14px;
}
.font13{
    font-size: 13px;
}
.font12{
    font-size: 12px;
}
.font10{
    font-size: 10px;
}

// -------Font weight---------
.font-bold{
    font-weight: bold;
}
.font-w600{
    font-weight: 600;
}
.font-w500{
    font-weight: 500;
}
.font-w300{
    font-weight: 300;
}
// ------Top values-----------

.top1{
    position: relative;
    top: 1px;
}
.top2{
    position: relative;
    top: 2px;
}
.top3{
    position: relative;
    top: 3px;
}
.top4{
    position: relative;
    top: 4px;
}
.top7{
    position: relative;
    top: 7px;
}
.top-n1
{
    position: relative;
    top: -1px;
}
.top-n3
{
    position: relative;
    top: -3px;
}
// -----bg color---------
// .bggray{
//     background-color: $bg-gray;
//     background: #f9fafb;
//     background: #f3f4f6;
// }
// ------ Height--------------
.h-20p{
height: 20px;
}
.h-22p{
    height: 22px;
}
// ------Width------------------
.w-16
{
  flex: 0 0 auto;
//   width: 16.66666667%;
width: 15.866667%;
}
.leftsidebaar
{
  position: fixed;
  flex: 0 0 auto;
  width: 16.66666667%;
}

// -----Margin classes------------
.mt-5p{
    margin-top: 5px !important;
}
.mr-10{
    margin-right: 10px;
}
.mt-9rem
{
    margin-top: 9.6rem;
}
// ---- accordains styles--------------

.accordion-button::after {
    flex-shrink: 0;
    width: 1rem;
    height: 1rem;
    margin-left: auto;
    background-size: 1rem;
}
.accordion-button:not(.collapsed) {
    // color: #272f3b;
    color: $black;
    background-color: $accordain-bg1;
    box-shadow: inset 0 -1px 0 rgba(0,0,0,.125);
}

// -----------------accordain------------------------
.accordion-button {
    width: 100%;
    padding: 0.65rem 0.9rem;
    font-size: 14px;
    font-weight: 600;
}

.accordion-body {
    padding: 0.75rem 0.275rem;
    padding-left: 0.75rem;
}

.accordion-button:focus {
    z-index: 3;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: none;
    // box-shadow: 0 0 0 0.25rem rgba(13,110,253,.25);
}

// ------------ Postion styles-------------
.pointer{
    cursor: pointer;
}
.absolute{
    display: -webkit-flex;
    display: flex;
    position: absolute;
    width: 100%;
}
.fixed{
    position: fixed;
    width: 100%;
    z-index: 1020;
}
// ------Header---------

header {
    background-color: $white;
    border-top: 2px Solid #d21513;
}
.navbar-default {
    background: $header-bg;
    border: none;
    margin-bottom: 0;
}

// --------- sub header ------
.submenu
{
    height: 40px;
    margin-top: -34px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
    background: $subheader-bg;
}

.linkcolor{
    color: $primarycolor;
}
// -------- Footer ------------
.footer{
    background-color: $footerbg;
}
footer {
    font-size: 14px;
    background-color: hsl(219deg 11% 40% / 5%);
    background-color: $white !important;
    box-shadow: 0px 2px 6px 0px #00000050;
    // padding-bottom: 12px !important;
}

// ---------Butons styles----------------

.send-button {
    background-color: transparent;
    background-image: linear-gradient(to bottom, #fff, #e8f6e8);
    background: $btn-bgsuccess;
    border: 0 solid #e5e7eb;
    border-radius: 0.15rem;
    box-sizing: border-box;
    color: $white;
    column-gap: 8px;
    cursor: pointer;
    display: flex;
    font-weight: 600;
    line-height: 6px;
    margin: 0;
    outline: 2px solid transparent;
    padding: 0.5rem 0.95rem;
    text-align: center;
    text-transform: none;
    transition: all 0.1s cubic-bezier(0.4, 0, 0.2, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    box-shadow: -6px 8px 10px rgba(81, 41, 10, 0.1), 0px 2px 2px rgba(81, 41, 10, 0.2);
}
.back-btn {
    position: relative;
    height: 30px;
    background: $white;
    border-radius: 0.15rem;
    // border: 1px solid #f1f1f1;
    border: 1px solid rgb(193 197 206);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    color: #172b4d;
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
    transition: all 0.2s;
    -webkit-user-select: none;
    user-select: none;
    letter-spacing: 1px;
}
.back-btn:hover{
    background: $white;
    border: 1px solid rgb(193 197 206);
}
.btnsend{
    position: relative;
    height: 30px;
    background: $btn-bgsuccess;
    // background: rgb(58 177 109);
    border: 0 solid #e5e7eb;
    border-radius: 0.15rem;
    border: 1px solid $success;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 14px;
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    box-sizing: border-box;
    color: $white;
    // color: #fff;
    column-gap: 2px;
    transition: all 0.2s;
    -webkit-user-select: none;
    user-select: none;
    letter-spacing: 1px;
    margin-top: -1px;
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
}
.resend-btn{
    position: relative;
    height: 30px;
    background: $btn-bgsuccess;
    border: 0 solid #e5e7eb;
    border-radius: 0.15rem;
    border: 1px solid $success;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    box-sizing: border-box;
    color: $white;
    column-gap: 3px;
    transition: all 0.2s;
    -webkit-user-select: none;
    user-select: none;
    letter-spacing: 1px;
    margin-top: -1px;
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
    text-wrap: nowrap;
}

.form-btn{
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 12px;
    border-style: ridge;
    height: 32px;
    width: 90px;
}
.viewdraft {
    color: $success;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 12px;
    background: $btn-bg;
    border: 0.1rem solid #3ab16d85 !important;
    border-style: ridge;
    height: 32px;
    width: 135px;
    text-wrap: nowrap;
}
.viewdraft:hover, .okbtn:hover {
    color: $success;
}

// .okbtn:hover{
//     color: $white;
//     font-weight: 600;
// }
.okbtn {
    color: $white;
    background: $btn-bgsuccess;
    border: 0.1rem solid #3ab16d85 !important;
}
.cancelbtn {
    color:  $danger;
    // border: 0.1rem solid #d215135e;
    background: #ffffff;
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
    transition: all 0.2s;
    font-weight: 600;
    color: #172b4d;
}
.deletebtn{
    color: $danger;
    border: 1px solid #d21513;
    background: $btn-bg;
    display: flex;
    align-items: center;
    font-size: 14px;
}
.savbtn{
    // color: #2486fc;
    color: $success;
    background: $btn-bg;
    border: 1px solid $success;
}
.add-option {
    // color: #1d4ed8;
    color: $success;
    font-size: 1.75rem;
    cursor: pointer;
    margin-right: 5px;
}

.note {
    font-size: 11px;
    font-size: 10px;
    color: $warning;
}
.cancelbtn:hover {
    color: #c3272e;
    border: 1px solid #d21513;
    background: #ffffff;
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
    transition: all 0.2s;
    font-weight: 600;
}
// -------Scrollbar css----------------------
.scrollbar
{
    position: absolute;
    margin-top: 53px;
    float: left;
    width: 100%;
    // background: #F5F5F5;
    height: calc(100vh - 13rem);
    overflow: auto;
    overflow-x: hidden;
    padding-bottom: 5px;
}
.no-fields{
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 18rem);
}

.right-scrollbar
{
    float: left;
    width: 100%;
    height: calc(100vh - 14.25rem);
    overflow: scroll;
    overflow-x: hidden;
    padding-bottom: 25px;
    padding-left: 8px;
}
.middle-scrollbar
{
    // height: 80vh;
    // height: calc(100vh - 9.25rem);
    // overflow: scroll;
    // overflow-y: auto;
    margin-top: 0.75rem;
    padding-right: 1px;
    width: 100%;
}

.force-overflow
{
	min-height: 100%;
    height: auto;
}

.scroll-style::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
    border-radius: 10px;
}

.scroll-style::-webkit-scrollbar
{
	width: 8px;
	background-color: #F5F5F5;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
}

.scroll-style::-webkit-scrollbar-thumb
{
	background-color: #172b4d;
    background: #888;
	border: 2px solid #555555;
    background-color: #c1c1c1;
    border: 1px solid #c1c1c1;
}

//--------- Modal styles-----------
.modal-title{
    font-size: 1.15rem;
}
.modal-body
{
    background:#f9f9f9;
    background:#f2f5f7;
    background:$white;
}
.modal-header
{
    background:$modal-bg;
    // padding: 0.65rem 0.85rem;
    padding: 0.5rem 0.85rem;
    justify-content: space-between;
}
.modal-footer {
    background:$modal-bg;
    // padding: 0.55rem 0.85rem;
    padding: 0.35rem 0.7rem;
}
.modalposition
{
    cursor: pointer;
}
.model-closeicon {
    font-size: 22px;
    cursor: pointer;
    position: relative;
    top: 4px;
}
.modal-mt
{
    position: relative;
    top: calc(2em * 5);
}
// .discard-modal{
//     max-width: 625px;
// }
.modal-mw{
    max-width: 550px;
}
.branding{
    max-width: 650px;
}
// ----- control heading-----------
.or_text{
    background: $controlheaders-bg;
    border-bottom: 2px solid rgb(229, 231, 235) !important;
    border-top: 1px solid rgb(212, 214, 216) !important;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}
.or_text span {
    padding: 4px 15px;
    padding-top: 8px;
    display: inline-block;
    font-weight: 600;
}

// -------Off canvas right side panel-----------
.offcanvas {
    position: fixed;
    bottom: 0;
    z-index: 111;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    visibility: visible;
    background-color: $white;
    background-clip: padding-box;
    outline: 0;
    transition: transform .3s ease-in-out;
}

.offcanvas-end {
    top: 10.05rem;
    right: 0px;
    height: 78%;
    height: calc(100vh - 10rem);
    flex: 0 0 auto;
    width: 15.866667%;
    border-left: 1px solid rgba(0, 0, 0, 0.2);
    transition: transform .3s ease-in-out;
}
.custome-offcanvas{
    top: 10.05rem !important;
    right: 0px !important;
    height: calc(100vh - 10rem);
    flex: 0 0 auto;
    width: 15.866667% !important;
    border-left: 1px solid rgba(0, 0, 0, 0.2);
    transition: transform .3s ease-in-out;
}
.offcanvas-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem 1rem;
    background: whitesmoke;
    background: $offcanvas-bg;
    // border-bottom:1px solid  rgb(128, 128, 128);
    // border-bottom:1px solid  rgba(0, 0, 0, 0.12);
    // border-bottom: 2px solid rgb(150 152 155 / 70%);
    border-bottom: 1px solid rgba(150, 152, 155, 0.5);
    // border-bottom-style: dashed;
}


// ------------------checkbox designs---------------
.check-box {
    position: relative;
    border: 2px solid #000;
    border-radius: 2px;
    background: none;
    cursor: pointer;
    line-height: 0;
    margin: 0 0.6em 0 0;
    outline: 0;
    padding: 0 !important;
    vertical-align: text-top;
    height: 20px;
    width: 20px;
    -webkit-appearance: none;
	appearance: none;
    opacity: 0.5;
}

.check-box:hover {
    opacity: 1;
}

.check-box:checked {
    background-color: #3ab16d;
    opacity: 1;
    border: 2px solid #3ab16d;
}

.check-box:before {
    content: "";
    position: absolute;
    right: 50%;
    top: 45%;
    width: 8px;
    height: 13px;
    border: solid #fff;
    border-width: 0 3px 3px 0;
    margin: 0px -2px 0 0px;
    transform: rotate(45deg) translate(-50%, -50%);
    z-index: 2;
}
.th2
{
    margin-left: -50px;
}
.th3
{
    margin-left: -50px;
}
.th4
{
    margin-left: -42px;
}
// -----------------------------------Media quries Laptops and Desktops ---------------------------------------------------------
@media (min-width: 928px) and (max-width: 1023px) {
    body {
        font-size: 11px;
    }
    .zoomflex-100 {
        display: flex;
        justify-content: flex-start;
    }
    .zoomclass-100 {
        transform-origin: left top;
    }
    // .zoom-75 {
    //     padding-bottom: calc(100vh - 29rem);
    // }
    // .zoom-50 {
    //     padding-bottom: calc(100vh - 29.5rem);
    // }
    .zoom-more {
        padding-bottom: calc(100vh - 29.49rem);
    }
    .zoomflex-50{
        display: flex;
        justify-content: center;
        overflow-x: hidden;
    }
    .zoomflex-75 {
        display: flex;
        justify-content: flex-start;
    }
    .doc-head
    {
      font-size: 0.65rem;
    }
    .font-recipent{
        font-size: 12px;
        white-space: nowrap;
        width: 160px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .form-select
    {
        background-position: right 0.5rem center;
    }
    .custme-p{
        padding: 0.15rem 3.25rem 0.15rem 0.5rem;
        padding-right: 28px;
    }
    .btnback {
        font-size: 11px;
        padding: 0 9px !important;
    }
    .send-button{
        column-gap: 1px;
        font-size: 11px;
        padding: 0.5rem 0.58rem;
    }
    .preview-img {
        width: 140px;
    }
    .w-16 {
        flex: 0 0 auto;
        width: 16.66667%;
        right: 1px;
    }
    .res-rightwidth
    {
        padding-left: 0px;
        position: relative;
        right: 3px;
    }
    .preview-icon {
        font-weight: 500;
        font-size: 18px !important;
        margin-left: 5px;
    }
    .font13 {
        font-size: 11px;
    }
    .font14, .form-select, .form-control, input, select, textarea {
        font-size: 11px;
    }
    .search{
        height: 30px;
    }
    .rect-box svg {
        height: 13px;
    }
    .rs-storke {
        height: 14px;
    }
    .list-group-item {
        padding: 0.45rem 0.6rem;
    }
    .custome-offcanvas {
        width: 16.66667% !important;
    }
    .box25{
        min-width: 1rem;
        align-items: center;
        width: 20px;
        height: 20px;
        .material-icons{
            font-size: 18px;
        }
    }
    .app-color-picker .preview .circle {
        height: 16px !important;
        width: 16px !important;
    }
    .accordion-button {
        font-size: 12px;
    }
    .font15 {
        font-size: 13px;
    }
    .font16 {
        font-size: 13px;
    }
    .modal-title {
        font-size: 0.8rem;
    }
    .w-120px{
        width: 120px !important;
    }
    .th2 {
        margin-left: -44px;
    }
    .th3 {
        margin-left: -45px;
    }
    .w-add96 {
        width: 97% !important;
        margin-left: 6px;
    }
    .w-add95{
        width: 95% !important;
        margin-left: 6px;
    }
    .form-btn{
        height: 30px;
        width: 85px;
        font-size: 12px;
    }
    .view-btn
    {
        padding: 0px 30px;
        font-size: 12px;
        height: 30px;
        span{
            font-size: 16px;
        }
    }
    #search-text {
        height: 36px;
    }
    .setting-header{
        label{
            font-size: 12px;
        }
    }
    .resend-btn {
        column-gap: 1px;
        font-size: 10px;
        padding: 0.5rem 0.25rem;
    }
     .zoomclass-75, .zoomclass-100 {
        transform-origin: left top;
    }
    #middle-section{
        padding-left: 5px;
    }
    .col-lg-10 > .middle-scrollbar > .zoomflex-75 {
        display: flex;
        justify-content: center;
    }
    .col-lg-10 > .middle-scrollbar > .zoomflex-75 > .zoomclass-75 {
        transform-origin: center top;
    }
    .col-lg-10{
        .middle-scrollbar{
            .zoomflex-100, .zoomflex-125, .zoomflex-150, .zoomflex-175, .zoomflex-200{
                display: flex;
                justify-content: start;
            }
            .zoomclass-100, .zoomclass-125, .zoomclass-150, .zoomclass-175, .zoomclass-200{
                transform-origin: left top !important;
            }
        }
    }
}
// --------------------- Ipad pro google--------------------------------------------------
@media (min-width: 1024px) and (max-width: 1099px) {
    body {
        font-size: 11px;
    }
    .docImages {
        background-color: #c3c3c3;;
        position: relative;
        width: 100%;
        overflow: auto;
        height: calc(100vh - 12.5rem);
        margin-bottom: 0px;
    }
    .zoom-more {
        padding-bottom: calc(100vh - 85.25rem);
    }
    .zoomflex-50, .zoomflex-75, .zoomflex-100 {
        display: block;
        justify-content: center;
    }
     .zoomclass-75, .zoomclass-100 {
        transform-origin: left top;
    }
    .zoomflex-50{
        display: flex;
        justify-content: center;
        overflow-x: hidden;
    }
    .doc-head
    {
      font-size: 0.65rem;
    }
    .font-recipent{
        font-size: 12px;
        white-space: nowrap;
        width: 160px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .form-select
    {
        background-position: right 0.5rem center;
    }
    .custme-p{
        padding: 0.15rem 3.25rem 0.15rem 0.5rem;
        padding-right: 28px;
    }
    .btnback {
        font-size: 11px;
        padding: 0 9px !important;
    }
    .send-button{
        column-gap: 1px;
        font-size: 11px;
        padding: 0.5rem 0.58rem;
    }
    .preview-img {
        width: 140px;
    }
    .w-16 {
        flex: 0 0 auto;
        width: 16.66667%;
        right: 1px;
    }
    .res-rightwidth
    {
        padding-left: 0px;
        position: relative;
        right: 3px;
    }
    .preview-icon {
        font-weight: 500;
        font-size: 18px;
        margin-left: 5px;
    }
    .font13 {
        font-size: 11px;
    }
    .font14, .form-select, .form-control, input, select, textarea {
        font-size: 11px;
    }
    .search{
        height: 30px;
    }
    .rect-box svg {
        height: 13px;
    }
    .rs-storke {
        height: 14px;
    }
    .list-group-item {
        padding: 0.45rem 0.6rem;
    }
    .custome-offcanvas {
        width: 16.66667% !important;
    }
    .box25{
        min-width: 1.5rem;
        align-items: center;
        width: 20px;
        height: 20px;
        .material-icons{
            font-size: 20px;
        }
    }
    .modal-title {
        font-size: 0.8rem;
    }
    .font16 {
        font-size: 12px;
    }
    .tablehead1 th{
        font-size: 10px;
    }
    .w-res{
       width: 98%;
    }
    .ml-n20{
        margin-left: 0px;
    }
    .ml-n40{
        margin-left: 0px;
    }
    .res-pl15{
        padding-left: 5px;
    }
    .w-120px{
        width: 120px !important;
    }
    .th2 {
        margin-left: -44px;
    }
    .th3 {
        margin-left: -45px;
    }
    .w-add96 {
        width: 97% !important;
        margin-left: 6px;
    }
    .form-btn{
        height: 30px;
        width: 85px;
        font-size: 12px;
    }
    .view-btn
    {
        padding: 0px 30px;
        font-size: 12px;
        height: 30px;
        span{
            font-size: 16px;
        }
    }
    #search-text {
        height: 36px;
    }
    .resend-btn {
        column-gap: 0px;
        font-size: 11px;
        padding: 0.5rem 0.25rem;
    }
    .resendicon {
        margin-left: 2px;
    }
}

// ------- Small some Devices 120% and 133%  Fire fox browser----------------------
@media (min-width: 1101px) and (max-width: 1160px) {
    .zoom-more {
        padding-bottom: calc(100vh - 35.35rem);
    }

    .zoomflex-50, .zoomflex-75, .zoomflex-100 {
        display: block;
        justify-content: center;
    }
    .zoomclass-75{
        transform-origin: center top;
    }
     .zoomclass-100 {
        transform-origin: left top;
    }
    .zoomflex-50, .zoomflex-75{
        display: flex;
        justify-content: center;
        overflow-x: hidden;
    }
    .doc-head
    {
      font-size: 0.75rem;
    }
    .font-recipent{
        font-size: 12px;
        white-space: nowrap;
        width: 160px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .form-select
    {
        background-position: right 0.5rem center;
    }
    .custme-p{
        padding: 0.15rem 3.25rem 0.15rem 0.5rem;
        padding-right: 28px;
    }
    .btnback {
        font-size: 12px;
        padding: 0 9px !important;
    }
    .send-button{
        column-gap: 1px;
        font-size: 12px;
        padding: 0.5rem 0.58rem;
    }
    .preview-img {
        width: 140px;
    }
    .w-16 {
        flex: 0 0 auto;
        width: 16.66667%;
        right: 1px;
    }
    .res-rightwidth
    {
        padding-left: 0px;
        position: relative;
        right: 3px;
    }
    .preview-icon {
        font-weight: 500;
        font-size: 18px;
        margin-left: 5px;
    }
    .font13 {
        font-size: 12px;
    }
    .font14, .form-select, .form-control, input, select, textarea {
        font-size: 12px;
    }
    .search{
        height: 30px;
    }
    .rect-box svg {
        height: 13px;
    }
    .rs-storke {
        height: 14px;
    }
    .list-group-item {
        padding: 0.45rem 0.6rem;
    }
    .custome-offcanvas {
        width: 16.66667% !important;
    }
    .box25{
        min-width: 1.5rem;
        align-items: center;
        width: 20px;
        height: 20px;
        .material-icons{
            font-size: 20px;
        }
    }
    @-moz-document url-prefix() {

    }
    .col-lg-10 > .middle-scrollbar > .zoomflex-125 {
        display: flex;
        justify-content: start;
      }
    .col-lg-10 > .middle-scrollbar > .zoomflex-125 > .zoomclass-125 {
        transform-origin: left top;
      }

}

// ------- Small some Devices 120% and 133% ----------------------
@media (min-width: 1161px) and (max-width: 1199px) {
    .zoom-more {
        padding-bottom: calc(100vh - 35.35rem);
    }

    .zoomflex-50, .zoomflex-75, .zoomflex-100 {
        display: block;
        justify-content: center;
    }
    .zoomclass-75{
        transform-origin: center top;
    }
     .zoomclass-100 {
        transform-origin: left top;
    }
    .zoomflex-50, .zoomflex-75{
        display: flex;
        justify-content: center;
        overflow-x: hidden;
    }
    .doc-head
    {
      font-size: 0.75rem;
    }
    .font-recipent{
        font-size: 12px;
        white-space: nowrap;
        width: 160px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .form-select
    {
        background-position: right 0.5rem center;
    }
    .custme-p{
        padding: 0.15rem 3.25rem 0.15rem 0.5rem;
        padding-right: 28px;
    }
    .btnback {
        font-size: 12px;
        padding: 0 9px !important;
    }
    .send-button{
        column-gap: 1px;
        font-size: 12px;
        padding: 0.5rem 0.58rem;
    }
    .preview-img {
        width: 140px;
    }
    .w-16 {
        flex: 0 0 auto;
        width: 16.66667%;
        right: 1px;
    }
    .res-rightwidth
    {
        padding-left: 0px;
        position: relative;
        right: 3px;
    }
    .preview-icon {
        font-weight: 500;
        font-size: 18px;
        margin-left: 5px;
    }
    .font13 {
        font-size: 12px;
    }
    .font14, .form-select, .form-control, input, select, textarea {
        font-size: 12px;
    }
    .search{
        height: 30px;
    }
    .rect-box svg {
        height: 13px;
    }
    .rs-storke {
        height: 14px;
    }
    .list-group-item {
        padding: 0.45rem 0.6rem;
    }
    .custome-offcanvas {
        width: 16.66667% !important;
    }
    .box25{
        min-width: 1.5rem;
        align-items: center;
        width: 20px;
        height: 20px;
        .material-icons{
            font-size: 20px;
        }
    }
    .tablehead1 th {
        font-size: 12px;
    }

    @-moz-document url-prefix() {

    }
    .w-120px{
        width: 120px !important;
    }
     .th2 {
       margin-left: -32px;
    }
    .th3 {
        margin-left: -45px;
    }
    .w-add96 {
        width: 97% !important;
        margin-left: 6px;
    }
    .form-btn{
        height: 30px;
        width: 85px;
        font-size: 12px;
    }
    .view-btn
    {
        padding: 0px 30px;
        font-size: 12px;
        height: 30px;
        span{
            font-size: 16px;
        }
    }
    #search-text {
        height: 36px;
    }
    .modal-title {
        font-size: 1rem;
    }
    .menu-list {
        width: 200px !important;
    }
    #middle-section{
        padding-left: 5px;
    }
    .col-lg-10 > .middle-scrollbar > .zoomflex-125 {
        display: flex;
        justify-content: start;
    }
    .col-lg-10 > .middle-scrollbar > .zoomflex-125 > .zoomclass-125 {
        transform-origin: left top;
    }

}

// ------- Small some Devices 125% and 110%- 100% small devices -----------------------------
@media (min-width: 1201px) and (max-width: 1299px) {
    body{
        font-size: 13px;
    }
    .img-control{
        width: 805px;
    }
    .document-img {
        height: 100%;
        width: 825px;
        display: block;
    }
    .leftsidebaar {
        position: fixed;
        flex: 0 0 auto;
        width: 16.666667%;
    }
    .res-rightwidth {
        flex: 0 0 auto;
        width: 13.666667%;
        position: absolute;
        right: 46px;
    }
    .res-midlewidth{
        flex: 0 0 auto;
        width: 64.666667%;
    }
    .btnsend{
        font-size: 12px;
        padding: 0 14px;
    }
    .btnback{
        font-size: 12px;
        padding: 0 13px !important;
    }
    .preview-icon {
        font-weight: 500;
        font-size: 18px;
        margin-left: 10px;
    }
    .custme-p{
        font-size: 12px !important;
        width: 100% !important;
    }
    .form-btn {
        box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 12px;
        border-style: ridge;
        height: 30px;
        width: 75px;
        font-size: 12px;
    }
    .send-button{
        column-gap: 1px;
        font-size: 12px;
        padding: 0.65rem 0.78rem;
    }
    .zoom-more {
        padding-bottom: calc(100vh - 34.69rem);
    }
    .zoomflex-50, .zoomflex-75, .zoomflex-100 {
        display: block;
        justify-content: center;
    }
    .custome-offcanvas{
        width: 16.66667% !important;
    }
    .w-16 {
        flex: 0 0 auto;
        width: 16.66667%;
        right: -2px;
    }

    @-moz-document url-prefix() {
        .zoomclass-125 .zoom-more {
            padding-bottom: calc(100vh - 36.69rem);
          }
        .zoomclass-150 .zoom-more, .zoomclass-175 .zoom-more, .zoomclass-200 .zoom-more {
            padding-bottom: calc(100vh - 36.69rem);
          }
    }
    .resend-btn {
        column-gap: 0px;
        font-size: 12px;
        padding: 0.65rem 0.5rem;
    }
    .resendicon {
        margin-left: 2px;
    }
    .menu-list {
        width: 175px !important;
    }
    .col-lg-10 >.middle-scrollbar >.zoomflex-100{
        display: flex;
        justify-content: left;
        padding-left: 30px;
    }
}

// ------- Small some Devices 110% and 125% -----------------------------
@media (min-width: 1300px) and (max-width: 1360px) {
    .leftsidebaar {
        position: fixed;
        flex: 0 0 auto;
        width: 15.666667%;
    }
    .res-pr2{
        padding-right: 0.9rem !important;
    }
    .btnsend{
        font-size: 12px;
        font-size: 12px;
        padding: 0 14px;
    }
    .btnback{
        font-size: 12px;
        padding: 0 14px;
    }
    .img-control {
        width: 915px;
    }
    .document-img {
        width: 915px;
    }
    .docImages {
        margin-right: 25px;
    }
    .send-button{
        column-gap: 1px;
        font-size: 14px;
        padding: 0.5rem 0.5rem;
    }
    .font15 {
        font-size: 14px;
    }
    .pdfcard
    {
        padding-left: 16px;
    }
    // .zoom-50 {
    //     padding-bottom: calc(100vh - 40rem);
    // }
    // .zoom-75 {
    //     padding-bottom: calc(100vh - 40rem);
    // }
    .zoom-more {
        padding-bottom: calc(100vh - 40.2rem);
    }
    td{
        .form-select, .form-control, input, select
        {
            width: 96% !important;
        }
    }
    .resend-btn {
        column-gap: 1px;
        font-size: 12px;
        padding: 0.5rem 0.5rem;
    }
    .resendicon {
        margin-left: 2px;
    }
    .menu-list {
        width: 205px !important;
    }
      .zoomflex-100{
        padding-left: 25px;
    }
    #middle-section{
        padding-left: 2px;
    }
    .zoomclass-100 {
        transform-origin: left top;
        transform: scale(0.945, 0.945) !important;
    }
    #middle-section{
        padding-left: 2px;
    }

}
  // ------- Small Edge browsers some Devices 110% and 125% --------------
  @media (min-width: 1361px) and (max-width: 1410px) {
    .leftsidebaar {
        position: fixed;
        flex: 0 0 auto;
        width: 15.666667%;
    }
    .res-pr2{
        padding-right: 0.9rem !important;
    }
    .btnsend{
        font-size: 12px;
        font-size: 12px;
        padding: 0 14px;
    }
    .btnback{
        font-size: 12px;
        padding: 0 14px;
    }
    .send-button{
        padding: 0.5rem 0.75rem;
    }
    .zoom-more {
        padding-bottom: calc(100vh - 39.49rem);
    }
    .zoomflex-50, .zoomflex-75, .zoomflex-100 {
        display: block;
        justify-content: center;
    }
    .resend-btn {
        padding: 0.5rem 0.45rem;
        font-size: 12px;
    }
    .resendicon {
        margin-left: 0px;
    }
    .menu-list {
        width: 220px !important;
    }
     .zoomflex-100 {
        display: flex;
        justify-content: center;
    }
    .zoomclass-100{
        transform: scale(0.975, 0.975);
    }
    #middle-section{
        padding-left: 0px;
    }

  }



// -----------------------large devices------------------------
@media screen and (min-width: 1400px) {
    // .container {
    //   width: 1370px;
    // }
    // .zoom-50 {
    //     margin-bottom: calc(100vh - 272rem);
    // }
    .zoom-more {
        padding-bottom: calc(100vh - 48.25rem);
    }
    .zoomflex-100{
        display: flex;
        justify-content: center;
        // padding-left: 1rem;
    }
    .zoomclass-75{
        margin-left: -45px;
    }
    .zoomclass-100{
        transform: scale(1, 1);
    }
    .col-lg-10 > .middle-scrollbar > .zoomflex-100 {
        display: flex;
        justify-content: center;
      }
    .col-lg-10 > .middle-scrollbar > .zoomflex-100 > .zoomclass-100 {
        transform-origin: center top;
      }
      .col-lg-10 > .middle-scrollbar > .zoomflex-125 {
        display: flex;
        justify-content: start;
        padding-left: 1.6rem;
      }
      .col-lg-10 > .middle-scrollbar > .zoomflex-125 > .zoomclass-125 {
        transform-origin: left top;
      }
  }

  // ------- Small some Devices 110% and 125% -----------------------
@media (min-width: 1420px) and (max-width: 1499px) {
    .zoom-more {
        padding-bottom: calc(100vh - 44.22rem);
    }
    .btnsend {
        column-gap: 3px;
        padding: 0 13px;
    }
    .col-lg-10 > .middle-scrollbar > .zoomflex-100 {
        display: flex;
        justify-content: flex-start;
        padding-left: 9rem;
      }
    .col-lg-10 > .middle-scrollbar > .zoomflex-100 > .zoomclass-100 {
        transform-origin: center top;
      }
}

  // ---------------------- High some Devices 100% and 90% -----------------
@media (min-width: 1500px) and (max-width: 1599px) {
    .zoom-more {
        padding-bottom: calc(100vh - 43.4rem);
    }
    .zoomclass-175 .zoom-more, .zoomclass-200 .zoom-more {
        padding-bottom: calc(100vh - 44.4rem);
      }
}



  @media screen and (min-width: 1600px) {
    // .container {
    //   width: 1570px;
    // }
    // .zoom-50 {
    //     margin-bottom: calc(100vh - 278rem);
    // }
    // .zoom-75 {
    //     margin-bottom: calc(100vh - 160.5rem);
    // }
    .zoom-more {
        padding-bottom: calc(100vh - 54.25rem);
    }
    .zoomflex-100{
    display: flex;
    justify-content: center;
     }
     .col-lg-10 >.middle-scrollbar >.zoomflex-125{
        display: flex;
        justify-content: center;
    }
    .col-lg-10 >.middle-scrollbar >.zoomflex-125 >.zoomclass-125{
        transform-origin: center top;
    }

  }
  @media (min-width: 1640px) and (max-width: 1700px) {
    .zoom-more {
        padding-bottom: calc(100vh - 49rem);
    }
    .ml-9 {
        margin-left: 14px;
    }
    .ml-15 {
        margin-left: 20px;
    }
}

  // ------------------ Large some Devices 90% and 80% ----------------------
    @media (min-width: 1701px) and (max-width: 1800px) {
        .zoom-more {
            padding-bottom: calc(100vh - 48.25rem);
        }
    }

  @media screen and (min-width: 1800px) {
    // .container {
    //   width: 1870px;
    // }
    // .zoom-50 {
    //     margin-bottom: calc(100vh - 281.5rem);
    // }
    // .zoom-75 {
    //     margin-bottom: calc(100vh - 166rem);
    // }
    // .cdk-drop-list{
    //     transform-origin: left top;
    // }
    .zoom-more {
        padding-bottom: calc(100vh - 57.9rem);
    }
  }
  @media (min-width: 1840px) and (max-width: 1920px) {
    .zoom-more {
            padding-bottom: calc(100vh - 55.25rem);
        }
        .ml-9 {
            margin-left: 22px;
        }
        .ml-15 {
            margin-left: 30px;
        }
        .zoomflex-125{
            display: flex;
            justify-content: center;
        }
        .zoomclass-125 {
            transform-origin: center top;
        }
        .col-lg-10 >.middle-scrollbar > .zoomflex-150 {
            display: flex;
            justify-content: center;
        }
        .col-lg-10 >.middle-scrollbar >.zoomflex-150 > .zoomclass-150 {
            transform-origin: center top;
        }
}

  @media screen and (min-width: 1921px)  {
    // .container {
    //   width: 1870px;
    // }
    // .zoom-50 {
    //     margin-bottom: calc(100vh - 288.59rem);
    // }
    // .zoom-75 {
    //     margin-bottom: calc(100vh - 177rem);
    // }
    .zoom-more {
        padding-bottom: calc(100vh - 59.25rem);
    }
    .zoomflex-125{
        display: flex;
        justify-content: center;
    }
    .zoomclass-125{
        transform-origin: center top;
    }
    .col-lg-10 {
        .middle-scrollbar {
            .zoomflex-150, .zoomflex-175{
                display: flex;
                 justify-content: center !important;
            }
            .zoomclass-150, .zoomclass-175 {
                transform-origin: center top !important;
          }
        }
      }
  }

  @media screen and (min-width: 1980px) and (max-width: 2100) {
    .zoom-more {
        padding-bottom: calc(100vh - 59.25rem);
    }
    .col-lg-10 .middle-scrollbar .zoomflex-150, .col-lg-10 .middle-scrollbar .zoomflex-175 {
        display: flex;
        justify-content: center !important;
    }
    .col-lg-10 >.middle-scrollbar >.zoomflex-150 > .zoomclass-150, .col-lg-10 >.middle-scrollbar >.zoomflex-175 > .zoomclass-175 {
        transform-origin: center top !important;
    }

}
@media screen and (min-width: 2300px) {
    .zoom-more {
        padding-bottom: calc(100vh - 64.85rem);
    }
    .zoomflex-150{
        display: flex;
        justify-content: center;
    }
    .zoomclass-150{
        transform-origin: center top;
    }
    @-moz-document url-prefix() {
        .zoom-more {
            padding-bottom: calc(100vh - 65.75rem);
          }
    }
    .col-lg-10 >.middle-scrollbar > .zoomflex-150, .col-lg-10 >.middle-scrollbar > .zoomflex-175 {
        display: flex;
        justify-content: center !important;
    }
    .col-lg-10 >.middle-scrollbar >.zoomflex-150 > .zoomclass-150, .col-lg-10 >.middle-scrollbar >.zoomflex-175 > .zoomclass-175 {
        transform-origin: center top !important;
    }
    .col-lg-10 > .middle-scrollbar > .zoomflex-200 {
        display: flex;
        justify-content: center;
      }
    .col-lg-10 > .middle-scrollbar > .zoomflex-200 > .zoomclass-200 {
        transform-origin: center top;
      }
}

  @media screen and (min-width: 2400px) {
    .zoom-more {
        padding-bottom: calc(100vh - 86.7rem);
    }
    // .zoom-50 {
    //     margin-bottom: calc(100vh - 310.6rem);
    // }
    // .zoom-75 {
    //     margin-bottom: calc(100vh - 198.5rem);
    // }
    .zoomflex-175,  .zoomflex-200{
        display: flex;
        justify-content: center;
    }
    .zoomclass-175,  .zoomclass-200{
        transform-origin: center top;
    }
  }