
// $bodybg:#E6E6E6;

$sidebarbg: #ffffff;
$headerbg: #F7F7F7;
$footerbg:#eff2f9;
$brandcolor:#c2282e;
$primarycolor: #0F6CBD;
$lightblue: rgba(0, 120, 212, 0.2588235294);
$borderblue: rgba(0, 120, 212, 0.9294117647);
$bgprimary: #0078d4;
$bg-gray:#ebebeb;
$icongray: #667988;
$bgwhiteblue: #f2f8fe;
$bglightgray:#f2f2f5;
$bordergray: #f2f2f5;

$textcolor: #172b4d;
$textblack: #000;
// $textcolor: #5a6270
$headingcolor: #5E6C84;
// box-shadow: var(--ds-shadow-overlay, 0 4px 8px -2px rgba(9, 30, 66, 0.25), 0 0 1px rgba(9, 30, 66, 0.31));

// ---New styles ---
$fontFamily: 'Lato', sans-serif;
$width:100%;
$height:100%;
// ---------------Background colors------------
$body-bg:#edebe8;
$header-bg: #272f3b;
$subheader-bg: #f3f4f6;
$controlheaders-bg: #f3f4f6;
$controlhover-bg: #edeff6;
$modal-bg: #f2f5f7;
$accordain-bg: #e7f1ff;
$accordain-bg1: #f3f4f6;
$offcanvas-bg: #f7f6f7;
$btn-bg: #fefefe;
$btn-bgsuccess: #3ab16d;
$bg-primary:#2486fc;
$bg-secondary:#212529;

// ---------------text colors------------
$white:#ffffff;
$black:#000;
$orange:#ff9c16;
$danger:#c3272e;
$success:#3ab16d;
$primary:#2486fc;
$warning:#e99406;
$textcolor-theme: #172b4d;
$textcolor-control: #212529;
// color: #f8f7fe;

